export const userPosts = [
      { user:'https://uploads-ssl.webflow.com/5f4da33ec5a46e999f9cc86e/5f4dc33abfdf4b5c21de3ca6_WhatsApp%20Image%202020-07-28%20at%206.55.46%20PM.jpeg', userName:'Abhishek Choudhary', caption:'Sukoon 🌻', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQEHweM_H0cSRr042JrwsPJGTEIs0Y1lSYEiQ&usqp=CAU' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9iFu4Vy6Y4KH9dBCgwpOQHRW53ED4xcyRKA&usqp=CAU', userName:'Ross Gellar', caption:'Nightmare!!', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRmsftouAw2qCYa7MN-WXQn4Hv1PWUgxmdB8g&usqp=CAU' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmQMRrWh9ujbanWscLEAKjfJ5ZNvmBVkSlzA&usqp=CAU', userName:'Dakota Jhonson', caption:'👀💕', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRwpPdEayCpl-PfxXoljsvdTIutAW8UxKeRow&usqp=CAU'},
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2oUtGcPau76x5vSRvhX2jjYUlhKa0flmEYw&usqp=CAU', userName:'Babu Rao Apte', caption:"Me waiting for my fav person's call", img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy0EMHrSzJLTvxCQTIkbsYNIQTG6ApDFA52w&usqp=CAU'},
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdiVlXzo1GRKmZLimvnpSEFZliv3-fih32Cw&usqp=CAU', userName:'Barkha Singh', caption:'Blessed since sunrise🤭', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmQeFOsCZ5iLmoQdjBiZke41oll9ImlGV3Bg&usqp=CAU'},
      { user:'https://st1.bollywoodlife.com/wp-content/uploads/2021/02/8-19.jpg', userName:'Selmon Bhoi', caption:'Please dekhlo yrr😭' , img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQ0bJ02WOJUJrWUu6waSWRb87rFdMmY122EA&usqp=CAU' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9r4AJ1mjA40jXhrFNi6ogGHlbkPxoEcHw5w&usqp=CAU', userName:'Professor', caption:'Get your Masks ready 👺', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNtn55RDakuZ_qAWVOqQJOy0TrNQpV8OWCCg&usqp=CAU' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDEAVEZolyQM7vS-R6jkmQmboNaLmH4lSLuQ&usqp=CAU', userName:'Chandler Bing', caption:'WooPah !', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI1QF7tgoWDLxpG110v2_fSt0LFiYKzAbSBw&usqp=CAU' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn3YYSWweXUExOTB_5zQh2kZS1yBHz28WLyg&usqp=CAU', userName:'Narendra Modi', caption:'Maza ma laife😌', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREMVt4SE0-MpT0EjqStN_aDxWQg_5AyHYB2g&usqp=CAU' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyvxB-T8EnRnlQRIzReQ42MAwxwrBxld0YPA&usqp=CAU', userName:'Anubhav Singh Bassi', caption:'Buddy!! aapke father aaye h🌝', img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0wgnUqj0SqRXbelaK26eN1MPJjgKnszOrZQ&usqp=CAU' },
  ];