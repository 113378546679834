export const userData = [
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9iFu4Vy6Y4KH9dBCgwpOQHRW53ED4xcyRKA&usqp=CAU', userName:'Ross Gellar', msg:'Pivot!' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmQMRrWh9ujbanWscLEAKjfJ5ZNvmBVkSlzA&usqp=CAU', userName:'Dakota Jhonson', msg:'imy 🥺' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdiVlXzo1GRKmZLimvnpSEFZliv3-fih32Cw&usqp=CAU', userName:'Barkha Singh', msg:'hehehehe 😘' },
      { user:'https://st1.bollywoodlife.com/wp-content/uploads/2021/02/8-19.jpg', userName:'Selmon Bhoi', msg:'Radhe dekhi kya?? 😬' },
      { user:'https://i0.wp.com/www.opindia.com/wp-content/uploads/2020/01/Rajnikanth.jpg?fit=621%2C414&ssl=1', userName:'Mr. Rajnikant', msg:'Yanna raskala 🐔' },
      { user:'', userName:'Unknown User', msg:'You blocked the contact' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9r4AJ1mjA40jXhrFNi6ogGHlbkPxoEcHw5w&usqp=CAU', userName:'Professor', msg:'Get Ready for parte 5 🌝' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSDEAVEZolyQM7vS-R6jkmQmboNaLmH4lSLuQ&usqp=CAU', userName:'Chandler Bing', msg:'ohhh my eyes...' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRn3YYSWweXUExOTB_5zQh2kZS1yBHz28WLyg&usqp=CAU', userName:'Narendra Modi', msg:'bhai kholde kya ab??' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2oUtGcPau76x5vSRvhX2jjYUlhKa0flmEYw&usqp=CAU', userName:'Babu Rao Apte', msg:'khopdi tod sale ka 😤' },
      { user:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyvxB-T8EnRnlQRIzReQ42MAwxwrBxld0YPA&usqp=CAU', userName:'Anubhav Singh Bassi', msg:'koi sense hai is baat ka 🤣🤣' },
  ];
